import Head from 'next/head';
import {
  baseUrl,
  GenerateOpenGraphPageMetaData,
  GenerateStaticPageMetaData
} from '@components/seo/MetaDataDefaults';

export const ProductSeo = ({props}) => {
  // console.log("[ProductSeo]:", props);
  const product = props?.product ?? props?.normalizedProducts?.parentVariant;
  // console.log('[ProductSeo]', {product});

  const childVariants = props?.normalizedProducts?.childVariants;

  const sku = product?.sku;
  const path = product?.path ?? product?.productPath;
  const currentPrice = product?.price;

  let images = product?.images?.map((image) => {
    return image?.url;
  });
  if (!images?.length) {
    images = product?.variants?.find((variant) => variant?.sku === product?.sku)?.images?.map((image) => {
      return image?.url;
    });
  }
  const firstImageUrl = images?.[0];
  // console.log("[images]:", firstImageUrl);

  const productKeywords = '';

  const dbSeoTitle = product?.seo_title ?? product?.seoTitle ?? props?.productData?.seoTitle
  // console.log("[SEO Title For Bundle]:", props?.productData?.seoTitle)
  const seoTitle = `${dbSeoTitle ?? sku} | Skar Audio`;
  const seoDescription = product?.seo_description ?? product?.seoDescription
  const canonicalUrl = `${baseUrl}${path}`;
  // console.log('[canonicalUrl]:', canonicalUrl);
  // console.log('[seoTitle]:', seoTitle);
  // console.log('[seoDescription]:', seoDescription);

  const firstProductDataVariant =
    product?.variants && product?.variants?.length > 0 ? product?.variants?.[0] : null;
  const productAvailability = firstProductDataVariant?.currentlyInStock === true;
  // console.log({seoTitle, seoDescription, canonicalUrl, availability})

  const childVariantsAvailability = !!(childVariants && childVariants?.some((item) => item.isInStock === true));

  let availability =
    productAvailability || childVariantsAvailability ? 'in stock' : 'out of stock';

  const productPreorderAvailability = product?.pre_order_info?.is_pre_order_eligible === true;
  if (productPreorderAvailability) {
    availability = 'preorder';
  }

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta charSet="UTF-8"/>
      <meta name="keywords" content={productKeywords}/>
      <meta name="description" content={seoDescription}/>
      <link rel="canonical" href={canonicalUrl}/>

      <GenerateStaticPageMetaData/>
      <GenerateOpenGraphPageMetaData
        title={seoTitle}
        url={canonicalUrl}
        description={seoDescription}
        image={firstImageUrl}
        type={'og:product'}
      />

      <meta property="product:retailer_item_id" content={sku}/>
      <meta property="product:price:amount" content={currentPrice}/>
      <meta property="product:availability" content={availability}/>
      <meta proeprty="product:mfr_part_no" content={sku}/>
      <meta property="product:condition" content="new"/>
      <meta property="product:price:currency" content="USD"/>
      <meta property="product:brand" content="Skar Audio"/>

      {firstImageUrl && <meta property="og:image" content={firstImageUrl}/>}
      {firstImageUrl && <meta property="og:image:secure_url" content={firstImageUrl}/>}
      {firstImageUrl && <meta property="og:image:alt" content={seoTitle}/>}
    </Head>
  );
};
