import {
  CC_TYPE_AMEX,
  CC_TYPE_DISCOVER,
  CC_TYPE_MASTERCARD,
  CC_TYPE_VISA
} from '@components/checkout-v3/components/v3-detect-card-brand.jsx';
import Image from 'next/image';
import AccountButton from '@components/accounts/helpers/Common';
import {usDate} from '@helpers/common/date.mjs';
import {useEffect, useRef, useState} from 'react';

export const cardIconMap = {
  [CC_TYPE_VISA]: '/image/checkout/visa-logo-blue-bg.svg',
  [CC_TYPE_AMEX]: '/image/checkout/amex-logo.svg',
  [CC_TYPE_DISCOVER]: '/image/checkout/discover-logo.svg',
  [CC_TYPE_MASTERCARD]: '/image/checkout/mastercard-log.svg'
};

export const AccountCreditCard_Card = ({card, handleEdit, deleteCreditCard}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const cardBrand = card?.card_brand;
  const cardIcon = cardIconMap[cardBrand];

  const deleteCard = async () => {
    setIsDeleting(true);
    await deleteCreditCard(card.id);
    setIsDeleting(false);
  };

  const togglePopup = () => setShowPopup(!showPopup);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);

  return (
    <div className="relative flex flex-col border rounded-md p-2 shadow-sm bg-white sm:justify-between sm:p-4 sm:gap-2 sm:h-full md:shadow-md">
      {/* Card Header */}
      {card?.is_default_card === 1 && (
        <div className="flex items-center justify-between w-full">
          <div className="text-sm font-medium" style={{color: 'rgb(96,95,95)', fontWeight: 600}}>
            Default Payment Method
          </div>
          <div className="relative">
            <div
              className="text-gray-500 cursor-pointer flex items-center justify-center w-6 h-6 rounded-full hover:bg-gray-200"
              onClick={togglePopup}>
              •••
            </div>
            {showPopup && (
              <div
                ref={popupRef}
                className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                <button
                  className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                  onClick={() => {
                    setShowPopup(false);
                    handleEdit();
                  }}>
                  Edit
                </button>
                <button
                  className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                  onClick={() => {
                    setShowPopup(false);
                    setConfirmDelete(true);
                  }}>
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Empty div to keep card info aligned properly */}
      {card?.is_default_card !== 1 && <div className="h-5 hidden lg:block"></div>}

      {/* Card Details */}
      <div className="flex items-center gap-3 w-full mt-1.5 sm:mt-0">
        {/* Card Icon */}
        <div className="flex items-center justify-center w-12 flex-shrink-0">
          {cardIcon ? (
            <Image src={cardIcon} alt={cardBrand} width={250} height={150} className="w-10" />
          ) : null}
        </div>

        {/* Last Four and Expiration */}
        <div className="flex flex-1 gap-x-2 flex-wrap">
          <div className="text-sm" style={{color: 'rgb(117, 117, 117)'}}>
            {`****${card?.card_no?.slice(-4)}`}
          </div>
          <div className="text-sm" style={{color: 'rgb(117, 117, 117)'}}>
            {`Exp ${card?.exp_month}/${card?.exp_year}`}
          </div>
        </div>

        {/* Three Dots for Non-Default Cards */}
        {card?.is_default_card !== 1 && (
          <div className="">
            <div
              className="text-gray-500 cursor-pointer flex items-center justify-center w-6 h-6 rounded-full hover:bg-gray-200 absolute top-4 right-4"
              onClick={togglePopup}>
              •••
            </div>
            {showPopup && (
              <div
                ref={popupRef}
                className="absolute top-10 right-4 w-48 bg-white border rounded shadow-lg z-10">
                <button
                  className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                  onClick={() => {
                    setShowPopup(false);
                    handleEdit();
                  }}>
                  Edit
                </button>
                <button
                  className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                  onClick={() => {
                    setShowPopup(false);
                    setConfirmDelete(true);
                  }}>
                  Delete
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Card Name */}
      {card?.card_nickname && (
        <div className="text-lg font-semibold mt-2 sm:mt-0">{card.card_nickname}</div>
      )}

      {/* Last Edited */}
      {card?.updated_at && (
        <div className="text-xs text-gray-400 mt-1">
          {`Last edited ${usDate(card?.updated_at)}`}
        </div>
      )}

      {/* Delete Confirmation */}
      {confirmDelete && (
        <div className="absolute top-0 left-0 right-0 w-full h-full bg-neutral-200/90 flex flex-col justify-center items-center px-4 sm:px-8">
          <div className="text-center text-sm md:text-base">
            Are you sure you want to delete this credit card?
          </div>
          <div className="flex gap-4 mt-4">
            <AccountButton onClick={deleteCard} submitting={isDeleting} danger>
              Yes, Delete
            </AccountButton>
            <AccountButton onClick={() => setConfirmDelete(false)}>Cancel</AccountButton>
          </div>
        </div>
      )}
    </div>
  );
};
