import PaymentMethodLogos from '@image/site/payment-methods-new.png';
import Link from 'next/link';
import Image from 'next/image';
import {useState} from 'react';
import {FooterSocialMediaIcons} from '@components/footer/FooterSocialMediaIcons';

export const FooterMobile = ({menus}) => {
  const [open, setOpen] = useState();
  return (
    <div className=''>
      {menus.map((item) => (
        <div key={item?.title} className={'font-oswald'}>
          <div
            onClick={() => setOpen(item?.title === open ? null : item?.title)}
            className='w-full cursor-pointer'>
            <div className='flex items-center justify-between my-2'>
              <h3 className='font-medium text-lg'>{item?.title}</h3>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-[16px] w-[16px] mr-1 duration-300'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                strokeWidth='2'
                style={{
                  transform: open === item?.title ? 'rotate(90deg)' : 'rotate(0deg)'
                }}>
                <path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7'/>
              </svg>
            </div>
          </div>

          <div>
            <div
              className='duration-300 overflow-hidden'
              style={{
                height: open === item?.title ? `${40 * item?.items?.length}px` : '0px'
              }}>
              <div className='ml-4 mt-2 divider-gradient'/>
              {item?.items?.map((data) => (
                <div className='font-normal ml-4 ' key={data.title}>
                  <Link href={data.path}>
                    <p className='my-[6px]'>{data.title}</p>
                  </Link>
                  <div className='divider-gradient'/>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      <div className={'space-y-6'}>
        <div className='flex justify-center items-center'>
          <FooterSocialMediaIcons/>
        </div>

        <div className={'flex justify-center'}>
          <div className='max-w-[380px]'>
            <Image
              src={PaymentMethodLogos}
              alt={
                'We accept American Express, Diners Club, Discover, Mastercard, Visa, Paypal, Amazon Pay, Apple Pay and Google Pay'
              }
              quality={90}
              sizes={'480px'}
            />
          </div>
        </div>

        <p className='text-white text-center text-xs leading-5'>
          All trademarks, including names, makes, models, and symbols of vehicle manufacturers
          mentioned on this site, belong to their respective owners. These trademarks are utilized
          solely to identify specific vehicle makes and models compatible with the products offered
          on this site. Skar Audio is not associated with, affiliated with, nor endorsed by any of
          these vehicle manufacturers.
        </p>
      </div>
    </div>
  );
};
