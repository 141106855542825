import PaypalLogo from '@image/checkout/paypal-logo.svg';
import AmazonPayLogo from '@image/checkout/amazon-pay-logo.svg';
import cn from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import {FormField} from '@components/checkout-v3/forms/form-fields.jsx';
import {cardIconMap} from '@components/accounts/v1-ui/AccountCreditCard.jsx';
import {useIsInDarkModeAndAppleDeviceCheck} from '@helpers/hooks/useWindow';
import {HStack, Spinner} from '@components/widgets/Common';

export function WhiteCard({children, className}) {
  return (
    <div className={`mb-0 md:mb-3 p-2 bg-white relative rounded ${className ? className : ``}`}>
      {children}
    </div>
  );
}

export function SectionHeader({children, className}) {
  return (
    <h3
      className={cn(
        'font-bold md:font-normal text-base md:text-lg md:uppercase font-oswald-desktop-only',
        className
      )}>
      {children}
    </h3>
  );
}

export const LargeRadio = (props) => {
  const isDarkMode = useIsInDarkModeAndAppleDeviceCheck();

  let radioClass = 'align-middle w-5 h-5';
  if (isDarkMode) {
    radioClass += ' custom-radio';
  }

  return <input type="radio" className={radioClass} {...props} />;
};

export function LargeRadioLabelAffirm(props) {
  const {label, ...inputProps} = props;
  const affirmIcon = '/image/checkout/affirm-logo.png';
  const affirmIconImage = affirmIcon ? (
    <Image src={affirmIcon} width={72} height={29} alt="Affirm" />
  ) : null;
  return (
    <div className="flex items-center gap-3">
      <LargeRadio {...inputProps} />

      <label htmlFor={props?.id} className="flex items-center gap-1 leading-4 cursor-pointer">
        {label}
        <div className="-mt-2 -translate-y-0.5">{affirmIconImage}</div>
      </label>
    </div>
  );
}

export function LargeRadioLabelPaypal(props) {
  const {label, right, ...inputProps} = props;

  return (
    <HStack gap={3}>
      <HStack className="justify-start items-center" gap={3}>
        <LargeRadio {...inputProps} />
        <label htmlFor={props.id} className="flex flex-1 cursor-pointer items-center">
          {label}
          <div className="pl-2">
            <Image src={PaypalLogo} width={72} height={29} alt="PayPal Logo" />
          </div>
        </label>
      </HStack>
      {right && <div>{right}</div>}
    </HStack>
  );
}

export function LargeRadioLabelAmazonPay(props) {
  const {label, right, ...inputProps} = props;

  return (
    <HStack gap={3}>
      <HStack className="justify-start items-center" gap={3}>
        <LargeRadio {...inputProps} />
        <label htmlFor={props.id} className="flex flex-1 cursor-pointer items-center">
          {label}
          <div className="pl-2 w-28">
            <Image src={AmazonPayLogo} width={176} height={34} alt="Amazon Pay Logo" />
          </div>
        </label>
      </HStack>
      {right && <div>{right}</div>}
    </HStack>
  );
}

export function LargeRadioLabelCreditOrDebitCard(props) {
  const {label, right, ...inputProps} = props;
  return (
    <HStack>
      <HStack className="justify-start items-center" gap={3}>
        <LargeRadio {...inputProps} />
        <label htmlFor={props.id} className="flex-1 cursor-pointer">
          {label}
        </label>
        <div>
          <HStack gap={1}>
            <Image src={cardIconMap?.Visa} width={32} height={28} alt="Visa" />
            <Image src={cardIconMap?.Mastercard} width={32} height={28} alt="Mastercard" />
            <Image
              src={cardIconMap?.['American Express']}
              width={32}
              height={28}
              alt="American Express"
            />
          </HStack>
        </div>
      </HStack>
      {right && <div className="text-sm">{right}</div>}
    </HStack>
  );
}

export const CheckoutLink = ({href, children, ...linkProps}) => {
  return (
    <Link href={href} {...linkProps} className="underline hover:no-underline">
      {children}
    </Link>
  );
};

export function Center({children, className}) {
  return (
    <HStack className={cn('justify-center', className)}>
      <div>{children}</div>
    </HStack>
  );
}

export function CheckoutButton({name, children, isWorking, disabled, onClick}) {
  return (
    <Center>
      <button
        type="submit"
        className="mx-auto d-block py-2 px-20 font-medium text-white
				bg-neutral-700 hover:bg-neutral-800 rounded
				disabled:bg-neutral-400"
        name={name}
        disabled={disabled}
        onClick={onClick}>
        <HStack className="justify-center items-center" gap={3}>
          {isWorking && <Spinner size={16} />}
          <div>{children}</div>
        </HStack>
      </button>
    </Center>
  );
}

export function FormFieldCardNumber({formData, onChange, ...otherProps}) {
  return (
    <FormField
      id="card_no"
      name="card_no"
      type="text"
      placeholder="Card Number"
      value={formData?.card_no ?? ''}
      onChange={onChange}
      {...otherProps}
    />
  );
}

export const SleekButton = ({onClick, href, children, ...otherProps}) => {
  return (
    <button
      className="border border-neutral-200 rounded py-0 px-2 bg-gray-100
        hover:bg-gray-200 disabled:bg-gray-50 uppercase
        shadow text-xs text-black"
      onClick={onClick}
      type="button"
      {...otherProps}>
      {children}
    </button>
  );
};
