import cn from 'clsx';
import Footer from '@components/footer/Footer';
import SignUp from '@components/footer/SignUp';
import {siteSettingsV2} from '@helpers/config-master.mjs';
import {popup_cookie_id, SubscriberPopup} from '@components/marketing/popup/subscriber-popup';
import {PushNotificationsWrapper} from '@components/marketing/PushNotificationWrapper';
import {Header} from '@components/header/Header';
import {getMenu} from '@helpers/get-menu.mjs';
import {Sidebar} from '@components/sidebar/Sidebar';
import {useCartUI} from '@components/cart/CartSideCartUiProvider';
import {CartSidebarMainComponent} from '@components/cart/CartSidebarView/CartSidebarMainComponent';
import {useMobileOrTablet} from '@helpers/hooks/useWindow';
import {useUI} from '@components/widgets/UiProvider';
import {CountdownTimer} from '@components/widgets/CountdownTimer/CountdownTimer';
import {useState} from 'react';
import {readCookie} from '@components/widgets/Cookies';
import {ChatWrapper} from '@components/chat/andrew-work/chat-wrapper';

const Layout = ({
                  children,
                  pageProps: {
                    paddingX,
                    hero,
                    bgColorClass,
                    bgColorClassMobile,
                    showSaleBanner = true,
                    showGeoTransitBanner = true,
                    showCountdownTimer = true,
                    showCountdownTimerMobile = true,
                    showPushNotificationPopup = true,
                    showEmailSubscriberPopup,
                    showPreHeader = true,
                    showFooterSignup = true,
                    showFooterSignupMobile = true,
                    showFooter = true,
                    disableMobileNav = false,
                    showNav = true,
                    showCartIcon = true,
                    showChatBubble = false
                  }
                }) => {
  const [hideShippingBannerHIOrAK, setHideShippingBannerHIOrAK] = useState(false);

  const isMobile = useMobileOrTablet();
  const isDesktop = !isMobile;

  const storeName = 'Skar Audio';
  const menu = getMenu();

  const bgColor = isMobile ? bgColorClassMobile : bgColorClass;
  const padding = paddingX ?? 'px-3 md:px-5';

  const {isCartOpen, closeCart} = useCartUI();
  const {displayMobileNav} = useUI();
  const sideBarCartIsNotOpen = showCartIcon === true && isCartOpen === false;

  showEmailSubscriberPopup =
    showEmailSubscriberPopup !== false && sideBarCartIsNotOpen && displayMobileNav === false;

  const saleSettings = siteSettingsV2.getSaleSettings();
  const layoutSettings = saleSettings?.layoutSettings;

  const SaleBanner = layoutSettings?.saleBanner;
  showSaleBanner = SaleBanner && showSaleBanner && layoutSettings?.showSaleBanner;

  const showCountdownTimerFromConfig = layoutSettings?.showCountdownTimer;
  if (showCountdownTimerFromConfig) {
    showCountdownTimer = showCountdownTimer && showCountdownTimerFromConfig;
    showCountdownTimerMobile = showCountdownTimerMobile && showCountdownTimerFromConfig;
  }

  const popupCookie = readCookie(popup_cookie_id);
  const showEmailSubscriberPopupFromConfig = layoutSettings?.showEmailSubscriberPopup;
  if (!showEmailSubscriberPopup) {
    if (showEmailSubscriberPopupFromConfig === false) {
      showEmailSubscriberPopup = false;
    } else {
      showEmailSubscriberPopup = showEmailSubscriberPopupFromConfig;
    }
  }

  const showPushNotificationPopupFromConfig = layoutSettings?.showPushNotificationPopup;
  if (showPushNotificationPopup) {
    if (showPushNotificationPopupFromConfig === false) {
      showPushNotificationPopup = false;
    } else {
      const isShowingEmailPopup =
        showEmailSubscriberPopup && popupCookie !== 'closed' && popupCookie !== 'subscribed';
      showPushNotificationPopup = showPushNotificationPopupFromConfig && !isShowingEmailPopup;
    }
  }
  // console.log({showEmailSubscriberPopup, showPushNotificationPopup});

  const showGeoTransitBannerFromConfig = layoutSettings?.showGeoTransitBanner;
  if (showGeoTransitBannerFromConfig) {
    showGeoTransitBanner = showGeoTransitBanner && showGeoTransitBannerFromConfig;
  }

  // console.log('[Layout][hideShippingBannerHIOrAK]:', hideShippingBannerHIOrAK);
  if (hideShippingBannerHIOrAK) {
    showGeoTransitBanner = false;
  }

  return (
    <div id="Layout" className="bg-white">
      <a href={'#mainContent'} className="p-4 focus:block sr-only focus:not-sr-only" tabIndex="0">
        Skip to main content
      </a>

      <div className="min-h-screen max-w-screen text-gray-700" role="document">
        <Header
          storeName={storeName}
          menu={menu}
          isMobile={isMobile}
          isDesktop={isDesktop}
          showPreHeader={showPreHeader}
          showNav={showNav}
          disableMobileNav={disableMobileNav}
          showCartIcon={showCartIcon}
        />

        <div role="complementary">
          {/*{showGeoTransitBanner && (*/}
          {/*  <TransitTimeGeoBanner setHideShippingBannerHIOrAK={setHideShippingBannerHIOrAK} />*/}
          {/*)}*/}

          {showSaleBanner && <SaleBanner/>}

          {showCountdownTimerMobile || showCountdownTimer ? (
            <>
              {showCountdownTimerMobile && showCountdownTimer ? <CountdownTimer/> : null}
              {showCountdownTimerMobile && !showCountdownTimer ? (
                <div className="xl:hidden">
                  <CountdownTimer/>
                </div>
              ) : null}
              {!showCountdownTimerMobile && showCountdownTimer ? (
                <div className="hidden lg:block">
                  <CountdownTimer/>
                </div>
              ) : null}
            </>
          ) : null}
        </div>

        <Sidebar isOpen={isCartOpen} handleClose={closeCart}>
          <CartSidebarMainComponent/>
        </Sidebar>

        <main role="main" id="mainContent" className={cn('relative', bgColor ?? 'bg-white')}>
          {hero && (
            <div className="hidden md:block" aria-hidden={true}>
              {hero}
            </div>
          )}
          <div className={cn(padding, 'mx-auto')}>{children}</div>

          {showFooterSignup && (
            <div className={showFooterSignupMobile ? '' : 'hidden md:block'}>
              <SignUp aria-label="Sign up form"/>
            </div>
          )}
        </main>

        {showFooter && <Footer aria-label="Footer section"/>}
      </div>

      {showEmailSubscriberPopup && <SubscriberPopup timeout={6000} forceOpen={false}/>}

      {showPushNotificationPopup && (
        <PushNotificationsWrapper aria-label="Push Notifications pop-up"/>
      )}

      {showChatBubble && <ChatWrapper/>}
    </div>
  );
};


export default Layout;
