import cn from 'clsx';
import Link from 'next/link';
import {priceHelper, returnHighestAndLowestPriceValues} from '@helpers/config-master.mjs';
import {useProductDetails} from '@helpers/hooks/useProduct';
import {pathJoin} from '@helpers/common/string.mjs';
import {niceMoney} from '@helpers/common/number.mjs';
import {useDatabaseCartProviderV3} from '@components/cart/CartProvider.jsx';
import {CartItemImage} from '@components/checkout-v3/layout/right/cart-item-image';
import {
  pushIncompatibleDown
} from '@helpers/subwoofer-compatibility/get-compatibility-mappings.mjs';
import {HStack, Spinner} from '@components/widgets/Common';
import {formatPriceNumeric} from '@components/product/global/formatPriceNumeric';
import {useWorking} from '@components/widgets/UseWorking';
import {useSession} from 'next-auth/react';
import {FaTrash} from '@assets/react-icons/fa';
import {DisplayChildProductsForBundle} from '@components/product/global/BundleHelpers';
import {useMobileOrTablet} from '@helpers/hooks/useWindow';
import {filterOutHiddenCartItemsSkus} from '@helpers/collections/filters.mjs';

export const CartItemsList = ({lineItems, onlyIncompatibleSubwoofers}) => {
  const isMobile = useMobileOrTablet();

  lineItems = filterOutHiddenCartItemsSkus(lineItems);
  lineItems = pushIncompatibleDown(lineItems, onlyIncompatibleSubwoofers);
  // console.log('[CartItemList]:', {lineItems});

  lineItems = lineItems?.map((oneLineItem) => {
    let displayedSku = oneLineItem?.sku;
    if (!displayedSku) {
      displayedSku = oneLineItem?.variant?.sku ?? oneLineItem?.sku;
    }
    if (displayedSku?.includes('FORD-2009-UP')) {
      displayedSku = displayedSku?.replace('FORD-2009-UP', 'FORD-2015-UP');
    }
    oneLineItem = {
      ...oneLineItem,
      displayed_sku: displayedSku
    };
    return oneLineItem;
  });

  return (
    <ul>
      {lineItems?.map((item, index) => {
        const itemKey = index + 1;
        const sku = item?.variant?.sku ?? item?.sku;

        return (
          <li key={itemKey}>
            <OneCartItem
              sku={sku}
              item={item}
              isCompatible={item?.isCompatible}
              isMobile={isMobile}
            />
          </li>
        );
      })}
    </ul>
  );
};

export const OneCartItem = ({sku, item, isCompatible = true, isMobile}) => {
  const productDetails = useProductDetails(sku);
  const msrpPrice = Number(item?.price_msrp);
  const mapPrice = Number(item?.price_map);

  const salePriceValue = item?.price;
  const isSaleEnabled = priceHelper?.saleEnabled === true ? true : false;

  let strikeoutPrice, normalPrice, salePrice, discountValue;
  if (isSaleEnabled) {
    strikeoutPrice = mapPrice;
    normalPrice = null;
    salePrice = salePriceValue;
    discountValue = salePriceValue - mapPrice; // negative
  } else {
    strikeoutPrice = msrpPrice;
    normalPrice = mapPrice;
    salePrice = null;
    discountValue = mapPrice - msrpPrice; // negative
  }

  strikeoutPrice = formatPriceNumeric(strikeoutPrice);
  normalPrice = formatPriceNumeric(normalPrice);
  salePrice = formatPriceNumeric(salePrice);
  discountValue = formatPriceNumeric(discountValue);

  return (
    <RenderOneCartItemInSidebar
      isMobile={isMobile}
      item={item}
      productDetails={productDetails}
      isSaleEnabled={isSaleEnabled}
      strikeoutPrice={strikeoutPrice}
      salePrice={salePrice}
      normalPrice={normalPrice}
      discountValue={discountValue}
      isCompatible={isCompatible}
      isPreOrder={item?.preorderInfo?.is_pre_order_enabled}
    />
  );
};

export const RenderOneCartItemInSidebar = ({
                                             isMobile,
                                             item,
                                             productDetails,
                                             isSaleEnabled,
                                             strikeoutPrice,
                                             salePrice,
                                             normalPrice,
                                             discountValue,
                                             isCompatible,
                                             isPreOrder
                                           }) => {
  // console.log('[RenderOneCartItemInSidebar] item:', item);
  // console.log('[RenderOneCartItemInSidebar] productDetails:', productDetails);
  const {data: session} = useSession();
  const isLoggedIn = session && !!session?.user?.email;
  const isDealer = isLoggedIn && session?.user?.role === 2;

  const msrpPrice = Number(item?.price_msrp);
  const mapPrice = Number(item?.price_map);
  // console.log('[RenderOneCartItemInSidebar][item]:', item);

  const [high, low] = productDetails
    ? returnHighestAndLowestPriceValues(productDetails, {isDealer})
    : [msrpPrice, mapPrice];

  const sku = item?.sku;

  const mainImage = item?.main_image ?? item?.variant?.images?.[0]?.url ?? productDetails?.main_image;

  return (
    <HStack
      gap={3}
      className={cn(
        'justify-between pt-4 pb-4 items-start',
        isCompatible ? '' : 'border-rose-500 border-2 my-1 py-1 px-2'
      )}>
      <div className="flex justify-start items-start gap-4 w-full">
        <CartItemImage sku={sku} mainImage={mainImage} quantity={item?.quantity}/>

        <div className="flex-grow">
          {!isDealer && (
            <SideCartInCheckoutRetail
              isMobile={isMobile}
              item={item}
              currentlyInStock={productDetails?.currentlyInStock}
              high={high}
              low={low}
              saleEnabled={isSaleEnabled}
              strikeoutPrice={strikeoutPrice}
              discountValue={discountValue}
              salePrice={salePrice}
              normalPrice={normalPrice}
              productDetails={productDetails}
              isCompatible={isCompatible}
              isPreOrder={isPreOrder}
            />
          )}

          {isDealer && (
            <SideCartInCheckoutDealer
              item={item}
              currentlyInStock={productDetails?.currentlyInStock}
              high={high}
              low={low}
              saleEnabled={isSaleEnabled}
              quantity={item?.quantity}
              productDetails={productDetails}
              isCompatible={isCompatible}
            />
          )}
        </div>
      </div>
    </HStack>
  );
};

const SideCartInCheckoutRetail = (props) => {
  const sku = props?.productDetails?.sku;
  const siteSlug = props?.productDetails?.slug;
  const discountValue = props?.discountValue;

  const lowPrice = props?.low;
  const highPrice = props?.high;

  const normalPrice = props?.normalPrice;
  const strikeOutPrice = props?.strikeoutPrice;

  const isInStock = props?.currentlyInStock === true;
  const isNotInStock = props?.currentlyInStock === false;

  const itemVariant = props?.item?.variant ?? props?.item;
  const isPreOrderEligible = !!itemVariant?.preorderInfo?.is_pre_order_eligible;
  const isPreOrderEnabled = !!itemVariant?.preorderInfo?.is_pre_order_enabled;
  // console.log({side_cart_in_checkout_props: props})
  // console.log({isPreOrderEligible})

  const childProducts = props?.item?.child_products;

  const isPreOrderEligibleAndEnabled = isPreOrderEligible && isPreOrderEnabled;
  const shouldSendAsPreOrderedItem = !isInStock && isPreOrderEligibleAndEnabled;

  const showIsNotInStockCartPricing = isNotInStock && !isPreOrderEligibleAndEnabled;
  const showIsSaleEnabledCartPricing = !!(!showIsNotInStockCartPricing && props?.saleEnabled);
  const showDefaultCardPricing = !showIsNotInStockCartPricing && !showIsSaleEnabledCartPricing;

  return (
    <>
      <h3 className="text-sm">
        <HStack>
          <div>
            {siteSlug ? (
              <Link href={pathJoin('/products', siteSlug)}>{sku}</Link>
            ) : (
              props?.item?.displayed_sku
            )}
          </div>
          <DeleteButtonForIncompatible
            item={props?.productDetails}
            isCompatible={props?.isCompatible}
          />
        </HStack>
      </h3>

      <DisplayChildProductsForBundle childProducts={childProducts}/>

      {showIsNotInStockCartPricing && (
        <IsNotInStockCartItemPricing lowPrice={lowPrice} highPrice={highPrice}/>
      )}

      {showIsSaleEnabledCartPricing && (
        <IsSaleEnabledCartItemPricing
          discountValue={discountValue}
          strikeoutPrice={props?.strikeoutPrice}
          salePrice={props?.salePrice}
        />
      )}

      {showDefaultCardPricing && (
        <DefaultCartItemPricing strikeoutPrice={strikeOutPrice} normalPrice={normalPrice}/>
      )}

      {shouldSendAsPreOrderedItem && <PreOrderItemNotice/>}
    </>
  );
};

export const PreOrderItemNotice = () => {
  return (
    <div className="flex-1 flex text-center align-center justify-start">
      <div
        className="border rounded rounded-2 text-xs pl-2 pr-2 border-gray-800"
        style={{
          backgroundColor: 'rgb(255,251,235)'
        }}>
        Pre-Order Item
      </div>
    </div>
  );
};

const IsSaleEnabledCartItemPricing = ({salePrice, strikeoutPrice, discountValue}) => {
  return (
    <div className="text-sm">
      <div className="flex flex-row gap-1">
        <span>Normal Price:</span>
        <span className="line-through">{niceMoney(strikeoutPrice)}</span>
      </div>

      <div className="text-sm flex flex-row gap-1 font-bold">
        {/*/!*span>Sale: </span> // @TODO - BEFORE SALE 11-23*/}
        <span>SALE PRICE: </span>
        <span style={{color: 'rgba(4,43,138, 1)'}}>{niceMoney(salePrice)}</span>
      </div>
    </div>
  );
};

const IsNotInStockCartItemPricing = ({lowPrice, highPrice}) => {
  return (
    <>
      <div className="flex justify-between text-sm">
        <span>Normal Price: ${niceMoney(lowPrice)}</span>
        <span>Price: {isNaN(highPrice) ? null : niceMoney(highPrice)}</span>
      </div>
    </>
  );
};

const DefaultCartItemPricing = ({strikeoutPrice, normalPrice}) => {
  return (
    <>
      <p className="text-sm">
        <span>MSRP: </span>
        <span className="line-through">{niceMoney(strikeoutPrice)}</span>
      </p>
      <p className="text-sm">
        <span>Price: {niceMoney(normalPrice)}</span>
      </p>
    </>
  );
};

function SideCartInCheckoutDealer({
                                    item,
                                    currentlyInStock,
                                    high,
                                    low,
                                    saleEnabled,
                                    quantity,
                                    productDetails,
                                    isCompatible
                                  }) {
  return (
    <>
      <h3 className="text-xs">
        <HStack>
          <div>
            {productDetails?.slug ? (
              <Link href={pathJoin('/products', productDetails?.slug)}>
                SKU: {productDetails?.sku}
              </Link>
            ) : (
              'SKU: ' + productDetails?.sku
            )}
          </div>
          <DeleteButtonForIncompatible item={item} isCompatible={isCompatible}/>
        </HStack>
      </h3>

      {!currentlyInStock ? (
        <>
          <p
            className="text-lg flex flex-row justify-between font-oswald"
            style={{color: '#ea580b'}}>
            <span>Preorder: </span>
            <span className="font-bold">{isNaN(low) ? null : niceMoney(low)}</span>
          </p>
        </>
      ) : saleEnabled ? (
        <>
          <div className="text-sm flex flex-row justify-between">
            <span>Unit Cost: </span>
            <span className="">{niceMoney(low)}</span>
          </div>
          <div className="text-sm flex flex-row justify-between">
            <span>Quantity: </span>
            <span className="">× {quantity}</span>
          </div>
          <div className="text-sm flex flex-row justify-between">
            <span>Line Total: </span>
            <span className="font-bold">{niceMoney(low * quantity)}</span>
          </div>
        </>
      ) : (
        <>
          <p className="text-sm font-light">
            <span>MSRP: </span>
            <span className="line-through">{niceMoney(high)}</span>
          </p>
          <p className="text-sm">
            <span>Unit Cost: {niceMoney(low)}</span>
          </p>
        </>
      )}
    </>
  );
}

export const DeleteButtonForIncompatible = ({isCompatible, item}) => {
  const {deleteLocalItem} = useDatabaseCartProviderV3();
  const {isWorking, wrapWorking} = useWorking();
  const onClick = wrapWorking(async () => {
    await deleteLocalItem(item);
  });
  return (
    <div>
      {!isCompatible && (
        <button onClick={onClick}>{isWorking ? <Spinner size={12}/> : <FaTrash/>}</button>
      )}
    </div>
  );
};
