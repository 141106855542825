import {useEffect, useState} from 'react';

export const useIsMobile = () => useMediaQuery('(max-width: 768px)');

export const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        let mediaQueryList;
        try {
            mediaQueryList = window.matchMedia(query);
            setMatches(mediaQueryList.matches);
        } catch (error) {
            console.error("[useMediaQuery] Error in useMediaQuery:", error);
            return;  // If an error occurred, we don't want to continue execution.
        }
        const listener = event => setMatches(event.matches);
        mediaQueryList.addListener(listener);
        return () => {
            mediaQueryList.removeListener(listener);
        };
    }, [query]);
    // console.log({matches});
    return matches;
};


export const useMobileOrTablet = () => {
    const [device, setDevice] = useState('');
    // console.log("[useMobileOrTablet][device]:", device);
    const checkDevice = () => {
        const width = window.innerWidth;
        if (width < 768) {
            setDevice('Mobile');
        } else if (width >= 768 && width <= 1024) {
            setDevice('Tablet');
        } else {
            setDevice('Desktop');
        }
    };

    useEffect(() => {
        checkDevice();
        window.addEventListener('resize', checkDevice);
        return () => {
            window.removeEventListener('resize', checkDevice);
        };
    }, []);

    const deviceIsMobile = device === 'Mobile' ? true : false;
    const deviceIsTablet = device === 'Tablet' ? true : false;

    const deviceIsUndefined = !device ? undefined : false;
    const isMobileOrTablet = (deviceIsMobile || deviceIsTablet) && !deviceIsUndefined
    // console.log("[useMobileOrTablet][isMobileOrTablet]:", isMobileOrTablet);
    return isMobileOrTablet;
};

export const useDeviceTypeWithLoading = () => {
    const [deviceType, setDeviceType] = useState('');
    // console.log("[useMobileOrTablet][deviceType]:", deviceType);

    const checkDevice = () => {
        const width = window.innerWidth;
        if (width < 768) {
            setDeviceType('Mobile');
        } else if (width >= 768 && width <= 1024) {
            setDeviceType('Tablet');
        } else {
            setDeviceType('Desktop');
        }
    };
    useEffect(() => {
        checkDevice();
        window.addEventListener('resize', checkDevice);
        return () => {
            window.removeEventListener('resize', checkDevice);
        };
    }, []);

    const deviceIsMobile = deviceType === 'Mobile' ? 'Mobile' : false;
    const deviceIsTablet = deviceType === 'Tablet' ? 'Tablet' : false;
    const deviceIsDesktop = deviceType === 'Desktop' ? 'Desktop' : false;

    const isLoading = !deviceType
    const isMobileOrTablet = (deviceIsMobile || deviceIsTablet) && !deviceIsDesktop

    return {
        isLoading: isLoading,
        isMobile: isMobileOrTablet,
        isDesktop: deviceIsDesktop,
        deviceType: deviceType,
    };
};


export const useDarkModeCheck = () => {
    const [isDarkMode, setIsDarkMode] = useState();
    const checkDarkMode = () => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(!mediaQuery?.matches);
    };

    useEffect(() => {
        checkDarkMode();
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        mediaQuery.addEventListener('change', checkDarkMode);
        return () => {
            mediaQuery.removeEventListener('change', checkDarkMode);
        };
    }, []);
    return isDarkMode;
};

export const useIsInDarkModeAndAppleDeviceCheck = () => {
    const isUserUsingAppleDevice = () => {
        const userAgent = navigator.userAgent;
        return /Macintosh|Mac OS X|iPhone/i.test(userAgent);
    };
    const isAppleDevice = isUserUsingAppleDevice();
    if (!isAppleDevice) return false;
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const isDarkMode = darkModeMediaQuery.matches;
    return isDarkMode;
};


export function scrollSmoothTo(item) {
    setTimeout(() => {
        const element = document.getElementById(item)
        const headerOffset = 100
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        })
    }, 300)
}


