import StrikeThroughPrice from '@imageTheme/mobile/BlackFriday2023/BF2023-X.webp';
import cn from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import {useState} from 'react';
import {niceMoney} from '../../../helpers/common/number.mjs';
import {formatPriceNumeric} from '../../../components/product/global/formatPriceNumeric';
import {HStack} from '../../../components/widgets/Common';
import {splitTitleFe} from '../../../components/FeHelpers';
import {
  getSubwooferHoleQtyText
} from '../../../components/vehicle-specific/compatibility/sub-compat-check';
import {InStockText} from '../../../components/dealer/dealer-product-card';
import {ReviewStarsFromDb} from '@components/product/reviews/review-stars-from-db';

export const DesktopProductTag = ({
                                    product,
                                    isParentSku,
                                    name,
                                    productLink,
                                    price,
                                    compareAtPrice,
                                    isPreorder,
                                    rating,
                                    isInStock,
                                    setImageSrc
                                  }) => {
  // console.log('[DesktopProductTag] product:', product);

  const childSkus = product?.childSkus;
  const firstChildSku = childSkus?.[0]?.sku;
  const [activeVariant, setActiveVariant] = useState(firstChildSku);

  const formattedPrice = price ? formatPriceNumeric(price) : null;
  compareAtPrice = formatPriceNumeric(compareAtPrice);

  const collectionPageSku = product?.collectionPageSku ?? product?.variant_parent_sku;
  const isNormalTypeProduct = product?.product_type === 'normal';

  let sku = isNormalTypeProduct
    ? product?.sku
    : collectionPageSku
      ? collectionPageSku
      : product?.sku;
  if (sku) {
    sku = sku?.replace('FORD-2009-UP', 'FORD-2015-UP');
  }

  const prodProperties = product?.properties;
  const isPreorderEligible = product?.preorderInfo?.is_pre_order_eligible === true;
  const isPreOrderEligibleAndActive = !isInStock && isPreorderEligible;

  const skuIsVehicleSpecificTrue = [
    'VS-',
    'VS.L',
    'VS-L',
    'L-VD',
    'L-SVR',
    'L-EVL',
    'L-SDR-',
    'L-VS'
  ];
  const skuIsVehicleSpecificFalse = ['VS-12', 'VS-10'];
  const skuIsVehicleSpecific =
    skuIsVehicleSpecificTrue?.some((prefix) => sku?.startsWith(prefix)) &&
    !skuIsVehicleSpecificFalse?.some((prefix) => sku?.startsWith(prefix));

  const variantSkus = product?.variantSkus;
  const variantSkusContainCoilConfig = variantSkus?.some(
    (sku) => sku?.includes('D1') || sku?.includes('D2') || sku?.includes('D4')
  );

  let displaySku = sku;
  if (variantSkus?.length > 1 && variantSkusContainCoilConfig) {
    const justCoilConfigs = variantSkus?.map((sku) => sku?.split(' ').pop());
    displaySku = `${displaySku} (${justCoilConfigs.join('/')})`;
  }

  const productTypeIsVariant = product?.product_type === 'variant' || variantSkus?.length > 1;
  if (productTypeIsVariant) {
    const nameContainsPipe = name?.includes('|');
    name = nameContainsPipe ? name?.split(' | ')?.[1] : name;
  }

  if (skuIsVehicleSpecific) {
    const isEmpty = product?.category === 'empty-subwoofer-enclosures';
    const isLoaded = product?.category === 'loaded-subwoofer-enclosures';

    const isPorted = product?.properties?.['Enclosure Type'] === 'Ported';
    const isSealed = product?.properties?.['Enclosure Type'] === 'Sealed';
    const portedOrSealed = isPorted ? 'Ported' : 'Sealed';

    const isCarpeted = !String(sku).endsWith('-AR');

    const rmsPowerRating = product?.properties?.['RMS Power'];
    const peakPowerRating = product?.properties?.['Peak Power'];

    const totalSubwooferHoles = product?.totalSubwooferHoles ?? null;
    const subwooferHoleQtyText = getSubwooferHoleQtyText(totalSubwooferHoles);
    const size = product?.properties?.['Subwoofer Size'];

    let productTitle = product?.site_name ?? product?.name;
    productTitle = splitTitleFe(productTitle, 'Compatible');

    if (isEmpty) {
      productTitle = splitTitleFe(productTitle, 'Compatible');
      productTitle =
        productTitle + ` Compatible ${subwooferHoleQtyText} ${size} Subwoofer Enclosure`;
    }

    if (isLoaded) {
      productTitle =
        prodProperties?.['Start Year'] +
        '-' +
        prodProperties?.['End Year'] +
        ' ' +
        prodProperties?.Make +
        ' ' +
        prodProperties?.Model +
        ' ' +
        prodProperties?.Trim +
        ' ' +
        ` Compatible ${subwooferHoleQtyText} ${size}` +
        ' Loaded Subwoofer Enclosure';
    }

    const targetString = 'Chevrolet/GMC Silverado 1500|Sierra 1500';
    if (productTitle.includes(targetString)) {
      productTitle = productTitle.replace(targetString, 'Chevrolet Silverado/GMC Sierra 1500');
    }

    if (String(sku) === 'VS.L-DODCHARG-20152020-1X10') {
      productTitle = '2015-2020 Dodge Charger Compatible Single 10" Loaded Subwoofer Enclosure';
    }

    if (String(sku) === 'L-VD-VS-1X10JWJL-2018-UP-L') {
      productTitle = '2018-2024 Jeep Wrangler JL Compatible Single 10" Loaded Subwoofer Enclosure (Left Side)';
    }

    if (String(sku) === 'L-VD-VS-1X10JWJL-2018-UP-R') {
      productTitle = '2018-2024 Jeep Wrangler JL Compatible Single 10" Loaded Subwoofer Enclosure (Right Side)';
    }

    productTitle = remapFord2009ModelTitlesOfArEnclosuresNotReorderedTo2015FakeSku(
      sku,
      productTitle
    );
    productTitle = productTitle?.replace('  ', ' ');

    return (
      <div id="product-card-product-info-container">
        <ProductSKU sku={sku} className={'text-xs'} />
        <ProductRating rating={rating} />

        <Link href={productLink}>
          <h2 className="py-1 px-2 text-[15px]">{productTitle}</h2>
        </Link>

        <ProductPricing
          compareAtPrice={compareAtPrice}
          price={formattedPrice}
          isPreorder={isPreorder}
        />
      </div>
    );
  } else if (isParentSku) {
    const handleChangeVariant = (childSku) => {
      const newImage = childSku?.image;
      setImageSrc(newImage);
      setActiveVariant(childSku?.sku);
    };

    return (
      <div id="product-card-product-info-container">
        <Link href={productLink} className="text-black">
          <h2 className="pt-1 px-2 text-primary text-[16.5px] cursor-pointer">{name}</h2>
        </Link>

        <div className="flex justify-between items-center mt-0.5">
          <ProductRating rating={rating} />
          <InStockText
            inStockTogether={isInStock}
            className="text-s mr-1.5 mt-0.5"
            isPreorder={isPreOrderEligibleAndActive}
            style={{
              fontWeight: '500'
            }}
          />
        </div>

        {childSkus ? (
          <div className={'mt-2 flex gap-2'}>
            {childSkus.map((childSku, index) => {
              const isSelected = activeVariant === childSku?.sku;
              return (
                <div
                  key={`${childSku?.sku}-${index}`}
                  onClick={() => {
                    handleChangeVariant(childSku);
                  }}
                  className={cn({
                    'border-2 font-medium px-4 py-2 hover:bg-gray-100 cursor-pointer': true,
                    'border-neutral-400 bg-neutral-200 hover:bg-neutral-200': isSelected
                  })}>
                  {childSku?.variant_option_one_value}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div id="product-card-product-info-container">
      <HStack className="items-start">
        <ProductSKU sku={displaySku} />

        <div className="flex flex-col items-end justify-end mt-1">
          <ProductRating rating={rating} />
          <InStockText
            inStockTogether={isInStock}
            className="text-s mr-1.5"
            isPreorder={isPreOrderEligibleAndActive}
            style={{
              fontWeight: '500'
            }}
          />
        </div>
      </HStack>

      <Link href={productLink} className="text-black">
        <h2 className="py-1 px-2 text-primary text-[16.5px] mb-1 cursor-pointer">{name}</h2>
      </Link>

      {formattedPrice && (
        <ProductPricing
          compareAtPrice={compareAtPrice}
          price={formattedPrice}
          isPreorder={isPreorder}
        />
      )}
    </div>
  );
};

export const ProductSKU = ({sku, className = ''}) => {
  // console.log('[ProductSKU]', {sku, className});
  // sku = removeParenthesesContent(sku);
  return (
    <div id="product-card-sku-snippet" className={`mt-0.5 pl-2 pt-1 text-gray-700 ${className}`}>
      <span className="font-bold">SKU</span>: {sku}
    </div>
  );
};

export const ProductRating = ({rating}) => {
  // const sku = rating?.sku;
  // if (sku === 'L-VD-VS-2X10GM-2014-2018-DCAB') {
  // }
  return (
    <div id="product-review-rating-snippet" className="mx-1">
      <ReviewStarsFromDb avgScore={rating?.avg_rating ?? 0} count={rating?.count ?? 0} role="img" />
    </div>
  );
};

export const ProductPricing = ({compareAtPrice, price, isPreorder}) => (
  <HStack className="justify-between items-start pr-6" role="group">
    <PricingInList compareAtPrice={compareAtPrice} price={price} isPreorder={isPreorder} />
  </HStack>
);

export const PricingInList = ({compareAtPrice, price, isPreorder}) => {
  // console.log({PricingInList: {compareAtPrice, price, isPreorder}});
  const fontColorClass = !isPreorder
    ? {color: 'rgba(138,4,43, 1)'} // custom red color
    : {color: 'rgba(4,43,138, 1)'}; // custom blue color (for black)

  return (
    <HStack className="justify-end">
      <PricingCompare compareAtPrice={compareAtPrice} />
      <PricingActual price={price} isPreorder={isPreorder} colorStyle={fontColorClass} />
    </HStack>
  );
};
export const PricingCompare = ({compareAtPrice}) => {
  return (
    <div
      className="px-1 text-lg text-accent-9 tracking-wide text-right decoration-green-500 decoration-2 relative">
      {niceMoney(compareAtPrice)}
      <div className={'absolute top-0 right-0 w-full h-full flex items-center justify-start'}>
        <div className={'opacity-80'}>
          <Image src={StrikeThroughPrice} alt="Strike Through Price" />
        </div>
      </div>
    </div>
  );
};

export const PricingActual = ({price, isPreorder, colorStyle}) => {
  // console.log({pricingActual: {price, isPreorder, colorStyle}});
  return (
    <div
      className={cn('-mt-2 px-1 font-semibold tracking-wide text-right font-oswald text-3xl')}
      style={colorStyle}>
      {niceMoney(price)}
    </div>
  );
};

const removeParenthesesContent = (inputString) => {
  if (!inputString) {
    return;
  }
  return inputString.replace(/\s*\([^)]*\)/, '');
};

const remapFord2009ModelTitlesOfArEnclosuresNotReorderedTo2015FakeSku = (sku, productTitle) => {
  const skuTitleMap = {
    'VS-1X10FORD-2015-UP-SUPERCREWCAB-V-AR':
      '2015-2024 Ford F-150 Super Crew Cab Compatible Single 10" Ported Armor Coated Subwoofer Enclosure',
    'VS-1X12FORD-2015-UP-SUPERCREWCAB-V-AR':
      '2015-2024 Ford F-150 Super Crew Cab Compatible Single 12" Ported Armor Coated Subwoofer Enclosure',
    'VS-2X10FORD-2015-UP-SUPERCREWCAB-AR':
      '2015-2024 Ford F-150 Super Crew Cab Compatible Dual 8" Sealed Armor Coated Subwoofer Enclosure',
    'VS-2X8FORD-2015-UP-SUPERCREWCAB-V-AR':
      '2015-2024 Ford F-150 Super Crew Cab Compatible Dual 8" Ported Armor Coated Subwoofer Enclosure',
    'VS-2X10FORD-2015-UP-SUPERCREWCAB-V-AR':
      '2015-2024 Ford F-150 Super Crew Cab Compatible Dual 10" Ported Armor Coated Subwoofer Enclosure'
  };
  return skuTitleMap[sku] || productTitle;
};
